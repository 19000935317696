import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../api'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import UIButton from '../../components/_UI/UIButton/UIButton'
import UIInput from '../../components/_UI/UIInput/UIInput'
import { useHistory, useLocation } from 'react-router-dom'
import './ResetPassword.scss'

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const ResetPassword = () => {
  const { t } = useTranslation('resetPassword')
  const history = useHistory()
  const query = useQuery()
  const token = query.get('token')

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordStatus, setPasswordStatus] = useState('default')
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
  const [disableButton, setDisableButton] = useState(true)

  const [noMatchPassword, setNoMatchPassword] = useState(false)
  const [regexError, setRegexError] = useState(false)
  const [generalError, setGeneralError] = useState(false)
  const [submit, setSubmit] = useState(false)

  // regex : Minimum eight characters, at least one letter and one number
  const passwordRegex = /^(?=(.*[0-9]))(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}$/

  useEffect(() => {
    setDisableButton(password === '' || confirmPassword === '')
  }, [password, confirmPassword])

  useEffect(() => {
    // if (!token) {
    //   history.push('/')
    // }
  }, [])

  const selectError = () => {
    if (noMatchPassword) {
      setPasswordStatus('error')
      setPasswordErrorMessage(t('noMatchPassword'))
      return
    }
    if (regexError) {
      setPasswordStatus('error')
      setPasswordErrorMessage(t('regexError'))
      return
    }
    if (generalError) {
      setPasswordStatus('error')
      setPasswordErrorMessage(t('passwordError'))
      return
    }
  }

  const sendNewPassword = async () => {
    setPasswordErrorMessage('')
    setSubmit(true)
    if (password && confirmPassword && password === confirmPassword) {
      if (passwordRegex.test(password)) {
        try {
          await api.sendNewPassword(password, token)
          history.push('/login')
        } catch (e) {
          console.log(e)
          setGeneralError(true)
        } finally {
          setSubmit(false)
        }
      } else {
        setRegexError(true)
      }
    } else {
      setNoMatchPassword(true)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (password && confirmPassword) {
        sendNewPassword()
      } else {
        document.getElementById('confirmPassword').focus()
      }
    }
  }

  useEffect(() => {
    selectError()
  }, [noMatchPassword, regexError, generalError])

  return (
    <div className="resetPassword">
      <div className="forgotPassword_circleBackground">
        <div className="forgotPassword_circleBackground--green">
          <div className="forgotPassword_circleBackground--red" />
        </div>
      </div>

      <div className="resetPasswordContainer">
        <div className="resetPasswordContainer_back">
          <UIBackButton
            onClick={() => {
              history.push('/forgot-password')
            }}
          />
        </div>

        <img
          className="resetPasswordContainer_forgotPassword"
          src="/images/logo-v-services-dec22.png"
          alt={t('altLogo')}
        />

        <p className="resetPasswordContainer_text">{t('resetPassword')}</p>

        <div className="resetPasswordContainer_form">
          <div className="resetPasswordContainer_input">
            <UIInput
              type="password"
              label={t('inputPassword')}
              placeholder={t('placeholderPassword')}
              value={password}
              onChange={(e) => {
                setNoMatchPassword(false)
                setRegexError(false)
                setGeneralError(false)
                setSubmit(false)
                setPassword(e)
              }}
              status={passwordStatus}
              enterkeyhint="next"
              onKeyPress={(event) => handleKeyPress(event)}
              style={{ width: 'calc(100% - 17px)' }}
            />
          </div>

          <div className="resetPasswordContainer_input">
            <UIInput
              type="password"
              id="confirmPassword"
              label={t('inputConfirmPassword')}
              placeholder={t('placeholderConfirmPassword')}
              value={confirmPassword}
              onChange={(e) => {
                setNoMatchPassword(false)
                setRegexError(false)
                setGeneralError(false)
                setSubmit(false)
                setConfirmPassword(e)
              }}
              status={passwordStatus}
              errorLabel={passwordErrorMessage}
              enterkeyhint="send"
              onKeyPress={(event) => handleKeyPress(event)}
              style={{ width: 'calc(100% - 17px)' }}
            />
          </div>

          <UIButton
            label={submit ? t('loading') : t('confirm')}
            kind="primary"
            backgroundColor={disableButton ? 'bgTransparent' : 'bgRed'}
            size="large"
            disabled={disableButton || submit}
            onClick={sendNewPassword}
          />
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
