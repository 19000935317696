import axios from 'axios'
import config from '../config'
import { isUserTokenExpired } from '../lib/helpers'
const api = axios.create({
  baseURL: config.API_BASE_URL,
})

const post = async (path, data = {}, headers = {}) => {
  const res = await api({
    method: 'post',
    url: path,
    data,
    headers,
  })
  return res.data
}

const put = async (path, data = {}, headers = {}) => {
  const res = await api({
    method: 'put',
    url: path,
    data,
    headers,
  })
  return res.data
}

const get = async (path, params = {}, headers = {}) => {
  const res = await api({
    method: 'get',
    url: path,
    params,
    headers,
  })
  return res.data
}

const getWithHeader = async (path, refreshToken) => {
  const conf = {
    method: 'get',
    url: path,
    headers: {
      Authorization: `bearer ${refreshToken}`,
      Accept: 'application/json',
    },
  }
  const res = await api(conf)
  return res.data
}

const submitCredentials = async (login, password) => {
  const response = await post('/token', { login, password })
  return response
}

const sendEmailForgotPassword = async (login) => {
  const response = await post('/password/forgot', { login })
  return response
}

const sendNewPassword = async (password, token) => {
  const response = await put(
    '/password',
    { password },
    {
      Authorization: `bearer ${token}`,
    }
  )
  return response
}

const refreshAccessToken = async (token) => {
  try {
    return await getWithHeader('/token', token)
  } catch (error) {
    console.log(error)
  }
}

const getFiles = async (id) => get(`/services/${id}/assets`)

const requestWithAuthorization = (request, token) => ({
  ...request,
  headers: {
    ...request.headers,
    Authorization: `bearer ${token}`,
  },
})

const fetchPaymentIntent = async (serviceBookingId, token) => {
  const response = await post(
    `/service-booking/${serviceBookingId}/payments`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response
}
const getNewsImage = async (publicationId, token) =>
  axios.get(`${config.API_BASE_URL}/news/${publicationId}/picture`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

const getResidenceFile = async (id, token, type) =>
  axios.get(`${config.API_BASE_URL}/residences/${id}/assets/${type}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

const getStripeTicket = async (commandId, token) => {
  const response = await get(
    `/payments/${commandId}/receipts`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  )
  return response
}

api.interceptors.request.use(
  (cfg) => {
    const originalRequest = cfg
    const isLogin = cfg.url.indexOf('/token') !== -1

    const accessToken = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')
    if (accessToken && !isLogin) {
      if (isUserTokenExpired(accessToken)) {
        return refreshAccessToken(refreshToken).then(
          (user) => {
            localStorage.setItem('access_token', user.access_token)
            localStorage.setItem('refresh_token', user.refresh_token)
            return Promise.resolve(
              requestWithAuthorization(originalRequest, user.access_token)
            )
          },
          () => Promise.reject(new Error('E_REFRESH_TOKEN_FAILED'))
        )
      }
    }
    let token = null
    if (accessToken) {
      token = cfg.method === 'get' && isLogin ? refreshToken : accessToken
    }
    if (token) {
      return requestWithAuthorization(cfg, token)
    }
    return cfg
  },
  (error) => Promise.reject(error)
)

export default {
  get,
  post,
  submitCredentials,
  refreshAccessToken,
  sendEmailForgotPassword,
  getFiles,
  sendNewPassword,
  fetchPaymentIntent,
  getNewsImage,
  getResidenceFile,
  getStripeTicket,
}
