import { gql } from '@apollo/client'

const FETCH_NEWSES = gql`
  query fetchNewes {
    newses(filter: { isRead: { isNull: true } }) {
      nodes {
        id
        body
        createdAt
        title
        idResidence
        publicationRange {
          end {
            value
            inclusive
          }
        }
      }
    }
  }
`

const HIDE_NOTIFICATION = gql`
  mutation hideNotification(
    $idNews: UUID!
    $idResident: UUID!
    $readAt: Datetime
  ) {
    createNewsStatus(
      input: {
        newsStatus: {
          idNews: $idNews
          idResident: $idResident
          readAt: $readAt
        }
      }
    ) {
      newsByIdNews {
        id
      }
    }
  }
`

export default {
  FETCH_NEWSES,
  HIDE_NOTIFICATION,
}
