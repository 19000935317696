import { gql } from '@apollo/client'

const RESIDENT = gql`
  query Resident($id: UUID!) {
    resident(id: $id) {
      residentNumber
      firstname
      lastname
      email
      mobilePhoneNumber
      birthdate
      idResidence
      building
      floor
      door
      residenceByIdResidence {
        address
        name
        cityByIdCity {
          postcode
          name
        }
        residenceCoordinationsByIdResidence {
          nodes {
            staffByIdCoordinator {
              firstname
              email
              mobilePhoneNumber
              lastname
            }
          }
        }
      }
    }
  }
`

const UPDATE_RESIDENT = gql`
  mutation UpdateResident(
    $id: UUID!
    $firstname: String!
    $lastname: String!
    $email: EmailAddress!
    $mobilePhoneNumber: MobilePhoneNumberFr!
    $birthdate: Date!
  ) {
    updateResident(
      input: {
        patch: {
          firstname: $firstname
          lastname: $lastname
          email: $email
          mobilePhoneNumber: $mobilePhoneNumber
          birthdate: $birthdate
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const RESIDENCE_SERVICES = gql`
  query ResidenceServices($id: UUID!) {
    serviceCategories(
      condition: { idParentCategory: null }
      orderBy: UPDATED_AT_DESC
    ) {
      nodes {
        id
        label
        servicesByIdServiceCategory {
          nodes {
            name
            label
            id
            description
            residenceServicesByIdService(
              filter: { idResidence: { equalTo: $id } }
            ) {
              nodes {
                id
                serviceStatusByIdServiceStatus {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

const DELETE_RESIDENT = gql`
  mutation MyMutation($id: UUID!) {
    anonymizeResident(input: { pResidentId: $id }) {
      clientMutationId
    }
  }
`

export default {
  RESIDENT,
  UPDATE_RESIDENT,
  RESIDENCE_SERVICES,
  DELETE_RESIDENT,
}
