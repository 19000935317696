import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useOnClickOutside from 'use-onclickoutside'
import MyAccountForm from '../../components/MyAccountForm/MyAccountForm'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import UIButton from '../../components/_UI/UIButton/UIButton'
import WidgetHeader from '../../components/_Widget/WidgetHeader/WidgetHeader'
import { External, Resident } from '../../gql'
import { getUserId, getUserRole } from '../../lib/helpers'
import { captureMessageWithAttachment } from '../../lib/sentry'
import './MyAccount.scss'

const MyAccount = () => {
  const { t } = useTranslation('myAccount')
  const access_token = localStorage.getItem('access_token')
  const infoUser = getUserRole(access_token)
  const history = useHistory()

  const [disableSaveModification, setDisableSaveModification] = useState(true)
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false)

  const [residentNumber, setResidentNumber] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstname, setFirstname] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [submitOk, setSubmitOk] = useState(false)

  const closeConfirmDeleteAccount = () => {
    setConfirmDeleteAccount(false)
  }

  const ref = useRef(null)
  useOnClickOutside(ref, closeConfirmDeleteAccount)

  let userRequest
  if (infoUser === 'logifim_resident') {
    userRequest = Resident.RESIDENT
  } else if (infoUser === 'logifim_external') {
    userRequest = External.EXTERNAL
  }

  let deleteUserRequest
  if (infoUser === 'logifim_resident') {
    deleteUserRequest = Resident.DELETE_RESIDENT
  } else if (infoUser === 'logifim_external') {
    deleteUserRequest = External.DELETE_EXTERNAL
  }

  let deleteUser = () => {}
  try {
    const rqst = useMutation(deleteUserRequest, {
      variables: {
        id: getUserId(access_token),
      },
      onCompleted: () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        history.push('/login')
      },
      onError: (err) => {
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables,
        }
        captureMessageWithAttachment(event, err)
      },
    })
    deleteUser = rqst[0]
  } catch (e) {
    console.log(e)
  }

  const { data: user } = useQuery(userRequest, {
    fetchPolicy: 'network-only',
    variables: {
      id: getUserId(access_token),
    },
    onCompleted: () => {
      if (user) {
        let usr
        if (infoUser === 'logifim_resident') {
          usr = user.resident
        } else if (infoUser === 'logifim_external') {
          usr = user.external
        }

        if (usr) {
          setResidentNumber(usr.residentNumber)
          setLastname(usr.lastname)
          setFirstname(usr.firstname)
          setEmail(usr.email)
          setPhoneNumber(usr.mobilePhoneNumber)
          setBirthdate(usr.birthdate)
        }
      }
    },
    onError: (err) => {
      const event = {
        request: userRequest?.definitions[0]?.name?.value,
        variables: {
          id: getUserId(access_token),
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  let updateUser = () => {}
  let loading
  let error
  let request
  let variables

  if (infoUser === 'logifim_resident') {
    request = Resident.UPDATE_RESIDENT
    variables = {
      id: getUserId(access_token),
      firstname,
      lastname,
      email,
      mobilePhoneNumber: phoneNumber,
      birthdate,
    }
  } else if (infoUser === 'logifim_external') {
    request = External.UPDATE_EXTERNAL
    variables = {
      id: getUserId(access_token),
      firstname,
      lastname,
      email,
      mobilePhoneNumber: phoneNumber,
      birthdate,
    }
  }

  try {
    const rqst = useMutation(request, {
      variables,
      onCompleted: () => {
        setSubmitOk(true)
        setDisableSaveModification(true)
      },
      onError: (err) => {
        const event = {
          request: request?.definitions[0]?.name?.value,
          variables,
        }
        captureMessageWithAttachment(event, err)
      },
    })
    updateUser = rqst[0]
    loading = rqst[1].loading
    error = rqst[1].error
  } catch (e) {
    console.log(e)
  }

  useEffect(() => {
    if (user) {
      let usr
      if (infoUser === 'logifim_resident') {
        usr = user?.resident
      } else if (infoUser === 'logifim_external') {
        usr = user?.external
      }

      if (
        lastname !== usr?.lastname ||
        firstname !== usr?.firstname ||
        email !== usr?.email ||
        phoneNumber !== usr?.mobilePhoneNumber ||
        birthdate !== usr?.birthdate
      ) {
        setDisableSaveModification(false)
      } else {
        setDisableSaveModification(true)
      }
    }
  }, [lastname, firstname, email, phoneNumber, birthdate])

  return (
    <div className="myAccount">
      <WidgetHeader />

      <div className="myAccount_back">
        <UIBackButton onClick={() => window.history.back()} />
      </div>

      {infoUser === 'logifim_resident' && (
        <div className="myResidence">
          <img
            className="myResidence_icon"
            src="icons/house.svg"
            alt={t('altHouse')}
          />
          <div className="myResidence_informations">
            <p className="myResidence_informations_text">{t('MyResidence')}</p>
            {user?.resident?.residenceByIdResidence?.name && (
              <p className="myResidence_informations_text myResidence_informations_text-bold">
                {user?.resident?.residenceByIdResidence?.name}
              </p>
            )}
            {user?.resident?.building && (
              <p className="myResidence_informations_text myResidence_informations_text-bold">
                {`${user?.resident?.building}`}
              </p>
            )}

            {user?.resident?.residenceByIdResidence?.address && (
              <p className="myResidence_informations_text myResidence_informations_text-bold">
                {user?.resident?.residenceByIdResidence?.address}
              </p>
            )}
            {user?.resident?.residenceByIdResidence?.cityByIdCity?.postcode &&
              user?.resident?.residenceByIdResidence?.cityByIdCity?.name && (
                <p className="myResidence_informations_text myResidence_informations_text-bold">
                  {`${user?.resident?.residenceByIdResidence?.cityByIdCity?.postcode} ${user?.resident?.residenceByIdResidence?.cityByIdCity?.name}`}
                </p>
              )}
          </div>
        </div>
      )}

      <MyAccountForm
        residentNumber={residentNumber}
        lastnameValue={lastname}
        lastnameOnChange={setLastname}
        firstnameValue={firstname}
        firstnameOnChange={setFirstname}
        emailValue={email}
        emailOnChange={setEmail}
        phoneNumberValue={phoneNumber}
        phoneNumberOnChange={setPhoneNumber}
        birthdayValue={birthdate}
        birthdayOnChange={setBirthdate}
        isFormModified={disableSaveModification}
        loading={loading}
        error={error}
        submitOk={submitOk}
        register={() => updateUser()}
      />

      {confirmDeleteAccount ? (
        <div className="confirmDeleteAccount" ref={ref}>
          <p className="confirmDeleteAccount_title">
            {t('deleteAccountTitle')}
          </p>
          <p className="confirmDeleteAccount_informations">
            {t('deleteAccountInformations')}
          </p>
          <UIButton
            label={t('confirmDeleteAccount')}
            onClick={() => deleteUser()}
            size="full"
            backgroundColor="bgWhite"
            color="red"
          />
        </div>
      ) : (
        <div className="personalPreferences">
          <p className="personalPreferences_title">{t('preferences')}</p>
          <UIButton
            label={t('passwordModif')}
            onClick={() => console.log('mdp')}
            size="full"
            backgroundColor="bgWhite"
            color="black"
          />
          <UIButton
            label={t('deleteAccount')}
            onClick={() => setConfirmDeleteAccount(true)}
            size="full"
            backgroundColor="bgRed"
            color="white"
          />
        </div>
      )}
    </div>
  )
}

export default MyAccount
