import { gql } from '@apollo/client'

const EXTERNAL = gql`
  query External($id: UUID!) {
    external(id: $id) {
      firstname
      lastname
      email
      mobilePhoneNumber
    }
  }
`

const UPDATE_EXTERNAL = gql`
  mutation UpdateExternal(
    $id: UUID!
    $firstname: String!
    $lastname: String!
    $email: EmailAddress!
    $mobilePhoneNumber: MobilePhoneNumberFr!
  ) {
    updateExternal(
      input: {
        patch: {
          firstname: $firstname
          lastname: $lastname
          email: $email
          mobilePhoneNumber: $mobilePhoneNumber
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const DELETE_EXTERNAL = gql`
  mutation MyMutation($id: UUID!) {
    anonymizeExternal(input: { pExternalId: $id }) {
      clientMutationId
    }
  }
`

export default {
  EXTERNAL,
  UPDATE_EXTERNAL,
  DELETE_EXTERNAL,
}
