import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import ServiceCard from '../ServiceCard/ServiceCard'
import './ServiceCategory.scss'

export default function ServiceCategory({ serviceCategorie }) {
  const [service, setService] = useState(false)

  const status = ['VISIBLE', 'INDISPONIBLE']
  const hasService = () => {
    serviceCategorie?.servicesByIdServiceCategory?.nodes?.map((service) => {
      if (
        status.find(
          (element) =>
            element ===
            service?.residenceServicesByIdService?.nodes[0]
              ?.serviceStatusByIdServiceStatus.name
        )
      ) {
        setService(true)
      }
    })
  }

  useEffect(() => {
    hasService()
  }, [])

  return (
    <div>
      {service && (
        <div className="serviceCategory">
          {serviceCategorie !== undefined && (
            <div className="serviceCategoryOpen_header">
              <p className="serviceCategoryOpen_title">
                {serviceCategorie?.label}
              </p>
            </div>
          )}
          {serviceCategorie?.servicesByIdServiceCategory?.nodes.length > 0 && (
            <div className="serviceCategoryOpen_services">
              {serviceCategorie?.servicesByIdServiceCategory?.nodes?.map(
                (service, index) =>
                  service?.residenceServicesByIdService?.nodes[0]?.id && (
                    <ServiceCard
                      service={service}
                      description={service.description}
                      key={`service-${index}`}
                    />
                  )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
ServiceCategory.propTypes = {
  serviceCategorie: PropTypes.objectOf(PropTypes.any),
}
