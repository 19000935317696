import { gql } from '@apollo/client'

const getInternalReasons = gql`
  query getInternalReasons {
    internalReasons {
      nodes {
        label
        id
      }
    }
  }
`

const createInternalMessage = gql`
  mutation createInternalMessage(
    $content: String!
    $idReason: UUID!
    $idSender: UUID!
    $idRecipient: UUID!
  ) {
    createInternalMessage(
      input: {
        internalMessage: {
          idSender: $idSender
          idRecipient: $idRecipient
          idReason: $idReason
          content: $content
        }
      }
    ) {
      clientMutationId
    }
  }
`

export default {
  getInternalReasons,
  createInternalMessage,
}
