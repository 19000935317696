import PropTypes from 'prop-types'
import './UIInput.scss'

export default function UIInput({
  kind = 'primary',
  size = 'normal',
  type = 'text',
  label = '',
  placeholder = '',
  value,
  onChange,
  onClick = null,
  id = null,
  ref = null,
  enterkeyhint = 'done',
  errorLabel = '',
  validIcon = 'v',
  status = 'default',
  autofocus = false,
  onKeyPress = null,
  disable = false,
  iconPath = '',
  ...props
}) {
  const inputClasses = `uiInput_field ${
    status === 'error' ? ' uiInput_field--error' : ''
  } ${status === 'valid' ? ' uiInput_field--valid' : ''} ${
    kind === 'secondary' ? ' uiInput_field--secondary' : ''
  } uiInput_field--${size}`

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <label className="uiInput">
      {label && <div className="uiInput_label">{label}</div>}
      <input
        type={type}
        className={inputClasses}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onClick={onClick}
        id={id}
        ref={ref}
        enterKeyHint={enterkeyhint}
        autoFocus={autofocus}
        onKeyPress={onKeyPress}
        disabled={disable}
        {...props}
      />
      {iconPath && (
        <div className="uiInput_icon">
          <img src={iconPath} alt="icon input" />
        </div>
      )}
      {status === 'error' && errorLabel !== '' && (
        <>
          <br />
          <span className="uiInput_error">{errorLabel}</span>
        </>
      )}
      {status === 'valid' && (
        <>
          <br />
          <span className="uiInput_valid">{validIcon}</span>
        </>
      )}
    </label>
  )
}

UIInput.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['normal', 'small', 'large', 'full']),
  type: PropTypes.oneOf(['text', 'password', 'email', 'tel', 'date']),
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errorLabel: PropTypes.string,
  validIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  status: PropTypes.oneOf(['default', 'error', 'valid']),
  enterkeyhint: PropTypes.oneOf([
    'enter',
    'done',
    'go',
    'next',
    'previous',
    'search',
    'send',
  ]),
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  id: PropTypes.string,
  ref: PropTypes.string,
  autofocus: PropTypes.bool,
  onKeyPress: PropTypes.func,
  disable: PropTypes.bool,
  iconPath: PropTypes.string,
  showVirtualKeyboard: PropTypes.bool,
}
