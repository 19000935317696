import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdMailOutline } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import useOnClickOutside from 'use-onclickoutside'
import api from '../../api'
import Notifications from '../../components/Notifications/Notifications'
import ServiceCategory from '../../components/ServiceCatagory/ServiceCategory'
//import UISelect from '../../components/_UI/UISelect/UISelect'
import UIButton from '../../components/_UI/UIButton/UIButton'
import WidgetHeader from '../../components/_Widget/WidgetHeader/WidgetHeader'
import WidgetResidentCard from '../../components/_Widget/WidgetResidentCard/WidgetResidentCard'
import { Newses as NewsesGql, Resident as ResidentGql } from '../../gql'
import { getUserId } from '../../lib/helpers'
import { captureMessageWithAttachment } from '../../lib/sentry'
import './Home.scss'

const Home = () => {
  const access_token = localStorage.getItem('access_token')
  const { t } = useTranslation('home')
  const history = useHistory()

  const [isInterogationOpen, setIsInterogationOpen] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [openedIndex, setOpenedIndex] = useState(null)
  const [residentLocation, setResidentLocation] = useState(null)
  const [residenceImage, setResidenceImage] = useState()

  const [fetchResidenceServices, { data: residenceServices }] = useLazyQuery(
    ResidentGql.RESIDENCE_SERVICES,
    {
      onError: (err) => {
        const request = ResidentGql.RESIDENCE_SERVICES
        const event = {
          request: request?.definitions[0]?.name?.value,
        }
        captureMessageWithAttachment(event, err)
      },
    }
  )

  const [hideNotification] = useMutation(NewsesGql.HIDE_NOTIFICATION)

  const { data: globalNotifications } = useQuery(NewsesGql.FETCH_NEWSES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const array = globalNotifications.newses.nodes
      array.map((item) => {
        const dateEnd = new Date(item.publicationRange.end.value)
        if (dateEnd.getTime() > new Date().getTime()) {
          api
            .getNewsImage(item.id, localStorage.getItem('access_token'))
            .then((response) => {
              let blob = new Blob([response.data], {
                type: response.headers['content-type'],
              })
              let imgUrl = URL.createObjectURL(blob)
              setNotifications((prev) => [...prev, { ...item, imgUrl }])
            })
            .catch(() => {
              setNotifications((prev) => [...prev, item])
            })
        }
      })
    },
  })

  const { data: resident } = useQuery(ResidentGql.RESIDENT, {
    fetchPolicy: 'network-only',
    variables: {
      id: getUserId(access_token),
    },
    onCompleted: (resident) => {
      setResidentLocation(resident.resident.residenceByIdResidence)
      api
        .getResidenceFile(
          resident.resident.idResidence,
          localStorage.getItem('access_token'),
          'visuel.png'
        )
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers['content-type'],
          })
          let imgUrl = URL.createObjectURL(blob)
          setResidenceImage(imgUrl)
        })
      fetchResidenceServices({
        variables: {
          id: resident?.resident?.idResidence,
        },
      })
    },
    onError: (err) => {
      const request = ResidentGql.RESIDENT
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: resident?.resident?.idResidence,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const closeInterogation = () => {
    setIsInterogationOpen(false)
  }

  const ref = useRef(null)
  useOnClickOutside(ref, closeInterogation)

  return (
    <div className="home">
      <WidgetHeader />
      <WidgetResidentCard
        residentImage={residenceImage && residenceImage}
        residentCity={residentLocation && residentLocation.cityByIdCity.name}
        residentAddress={residentLocation && residentLocation.address}
      />
      {isInterogationOpen ? (
        <div className="interogationOpen" ref={ref}>
          <div className="interogationOpen_button">
            <UIButton
              label={t('question')}
              onClick={() => history.push('/contact-form')}
              size="full"
              backgroundColor="bgWhite"
              color="green"
            />
          </div>
          <div className="interogationOpen_button">
            <UIButton
              label={t('sinister')}
              onClick={() => history.push('/incident-form')}
              size="full"
              backgroundColor="bgWhite"
              color="black"
            />
          </div>

          <button
            type="button"
            className="interogation"
            onClick={() => setIsInterogationOpen(false)}
          >
            <MdMailOutline />
          </button>
        </div>
      ) : (
        <button
          type="button"
          className="interogation"
          onClick={() => setIsInterogationOpen(true)}
        >
          <MdMailOutline />
        </button>
      )}

      <div className="notifications">
        {/* <UISelect
          size="medium"
          options={test}
          onChange={(e) => setstate(e.target.value)}
          value={state}
        /> */}
        {notifications &&
          notifications.map((notification, index) => {
            if (notification.hide) {
              return null
            }
            if (index < 5)
              return (
                <Notifications
                  image={notification.imgUrl ? notification.imgUrl : ''}
                  key={`notification${index}`}
                  notification={notification}
                  display={(i) => {
                    const nts = notifications.filter((notif) => notif.id !== i)
                    setNotifications(nts)
                    hideNotification({
                      variables: {
                        idNews: i,
                        idResident: getUserId(access_token),
                        readAt: new Date(),
                      },
                    })
                  }}
                  opened={() => {
                    const nts = [
                      ...notifications.map((n) => ({
                        ...n,
                        opened: false,
                      })),
                    ]
                    if (index !== openedIndex) {
                      nts[index].opened = true
                      setOpenedIndex(index)
                    } else {
                      setOpenedIndex(null)
                    }
                    setNotifications(nts)
                  }}
                />
              )
          })}
      </div>

      <div className="services">
        {residenceServices?.serviceCategories?.nodes.map(
          (serviceCategorie, index) => (
            <ServiceCategory
              serviceCategorie={serviceCategorie}
              key={`ServiceCategory-${index}`}
            />
          )
        )}
      </div>
    </div>
  )
}

export default Home
