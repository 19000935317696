import PropTypes from 'prop-types'
import './UIHeaderForm.scss'

export default function UIHeaderForm({ title, subTitle }) {
  return (
    <div className="uiHeaderForm">
      <text className="uiHeaderForm_title">{title}</text>
      <text className="uiHeaderForm_subTitle">{subTitle}</text>
    </div>
  )
}

UIHeaderForm.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}
