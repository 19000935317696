import React from 'react'
import { PropTypes } from 'prop-types'
import './UITextArea.scss'

const UITextArea = ({ placeholder, onChange, count, className }) => {
  //const { t } = useTranslation('forgotPassword')
  return (
    <textarea
      className={`uiTextArea ${className}`}
      placeholder={placeholder}
      onChange={onChange}
      value={count}
      maxLength={1500}
    ></textarea>
  )
}

UITextArea.defaultProps = {
  count: undefined,
  className: '',
  placeholder: '',
}

UITextArea.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number,
  className: PropTypes.string,
}

export default UITextArea
