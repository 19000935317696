import PropTypes from 'prop-types'
import './UISelect.scss'

export default function UISelect({
  size = 'full',
  options,
  onChange,
  disabled = false,
  ...props
}) {
  return (
    <div className={`UISelect UISelect--${size}`}>
      <label className="drop-down" htmlFor="UISelect">
        <select
          className="UISelect_select"
          id="UISelect"
          onChange={onChange}
          disabled={disabled}
          {...props}
        >
          {options.map((item) => (
            <option key={item.id} className="UISelect_options" value={item.id}>
              {item.name || item.label}
            </option>
          ))}
        </select>
        <svg className="UISelect_svg">
          <use xlinkHref="#select-arrow-down" />
        </svg>
      </label>

      <svg className="UISelect_svg">
        <symbol id="select-arrow-down" viewBox="0 0 10 6">
          <polyline points="1 1 5 5 9 1" />
        </symbol>
      </svg>
    </div>
  )
}

UISelect.propTypes = {
  size: PropTypes.oneOf(['medium', 'full']),
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
