/* eslint-disable no-useless-escape */
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import api from '../../api'
import UIButton from '../../components/_UI/UIButton/UIButton'
import UIInput from '../../components/_UI/UIInput/UIInput'
import config from '../../config'
import { isUserTokenExpired } from '../../lib/helpers'
import './Login.scss'

const Login = () => {
  const { t } = useTranslation('login')
  const history = useHistory()

  const [login, setLogin] = useState(
    config.NODE_ENV === 'development' ? 'resident@test.com' : ''
  )
  const [password, setPassword] = useState(
    config.NODE_ENV === 'development' ? '12345' : ''
  )
  const [loginStatus, setLoginStatus] = useState('default')
  const [errorMessage, setErrorMessage] = useState()
  const [noAccess, setNoAccess] = useState(false)

  const sendLogin = async () => {
    let response
    try {
      response = await api.submitCredentials(login.toLowerCase(), password)
      if (response.access_token) {
        const usr = jwtDecode(response.access_token)
        if (config.authorizedRole.indexOf(usr.role) !== -1) {
          localStorage.setItem('access_token', response.access_token)
          localStorage.setItem('refresh_token', response.refresh_token)
          history.push('/home')
        } else {
          setNoAccess(true)
        }
      }
    } catch (e) {
      setLoginStatus('error')
      setErrorMessage('errorLogin')
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (password) {
        sendLogin()
      } else {
        document.getElementById('loginPassword').focus()
      }
    }
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken && !isUserTokenExpired(accessToken)) {
      history.push('/home')
    }
  }, [])

  return (
    <div className="login">
      <div className="circleBackground">
        <div className="loginContainer">
          <img
            className="logoLogifirm-login"
            src="/images/logo-v-services-dec22.png"
            alt={t('altLogo')}
          />
        </div>
      </div>
      <div className="secondContainer">
        <div className="loginContainer_inputs">
          <UIInput
            type="text"
            label={t('inputLogin')}
            placeholder={t('placeholderLogin')}
            value={login}
            onChange={setLogin}
            enterkeyhint="next"
            status={loginStatus}
            onKeyPress={(event) => handleKeyPress(event)}
            errorLabel=""
            size="full"
          />

          <UIInput
            type="password"
            label={t('inputPassword')}
            placeholder={t('placeholderPassword')}
            value={password}
            onChange={setPassword}
            id="loginPassword"
            enterkeyhint="send"
            status={loginStatus}
            onKeyPress={(event) => handleKeyPress(event)}
            errorLabel={t(errorMessage)}
            size="full"
          />
          <button
            className="loginContainer_forgotPassword"
            type="button"
            onClick={() =>
              history.push({
                pathname: '/forgot-password',
                state: {
                  login,
                },
              })
            }
          >
            {t('forgotPassword')}
          </button>
        </div>

        <div className="loginContainer_buttons">
          <UIButton
            label={t('connect')}
            size="full"
            backgroundColor="bgGreen"
            onClick={sendLogin}
          />
          <UIButton
            label={t('back')}
            kind="secondary"
            color="black"
            backgroundColor="bgTransparent"
            size="full"
            onClick={() => history.push('/')}
          />
        </div>
        {noAccess && <p style={{ color: 'red' }}>{t('noAccess')}</p>}
      </div>
    </div>
  )
}

export default Login
