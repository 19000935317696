import React from 'react'
import './WidgetResidentCard.scss'
import PropTypes from 'prop-types'

export default function WidgetResidentCard({
  residentImage,
  residentCity,
  residentAddress,
}) {
  return (
    <a href="/my-residence" className="link">
      <div className="container">
        <img
          className="residentImage"
          src={residentImage}
          alt="resident_logo"
        />
        <div className="redidentDetail">
          <p className="resident_location_city">{residentCity}</p>
          <p className="resident_location_address">{residentAddress}</p>
        </div>
      </div>
    </a>
  )
}

WidgetResidentCard.defaultProps = {
  residentImage: '',
  residentCity: '',
  residentAddress: '',
}

WidgetResidentCard.propTypes = {
  residentImage: PropTypes.string,
  residentCity: PropTypes.string,
  residentAddress: PropTypes.string,
}
