import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import './WidgetHeader.scss'
import { Resident as ResidentGql } from '../../../gql'
import { getUserId } from '../../../lib/helpers'
import { captureMessageWithAttachment } from '../../../lib/sentry'

export default function WidgetHeader({ fromServices }) {
  const { t } = useTranslation('menu')
  const history = useHistory()
  const access_token = localStorage.getItem('access_token')

  const installPwa = async () => {
    if (window.bcdDeferredPrompt !== null) {
      window.bcdDeferredPrompt.prompt()
      const { outcome } = await window.bcdDeferredPrompt.userChoice
      if (outcome === 'accepted') {
        window.bcdDeferredPrompt = null
      }
    }
  }

  const { data: resident } = useQuery(ResidentGql.RESIDENT, {
    fetchPolicy: 'network-only',
    variables: {
      id: getUserId(access_token),
    },
    onError: (err) => {
      const request = ResidentGql.RESIDENT
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: resident?.resident?.idResidence,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <div className="header">
      {isMenuOpen ? (
        <div className="openHeader">
          <button
            type="button"
            className="openHeader_close"
            onClick={() => setIsMenuOpen(false)}
          >
            <img
              className="openHeader_cross"
              src="/icons/closeWhite.svg"
              alt={t('close')}
            />
          </button>

          <div className="openHeader_menu">
            <p className="openHeader_hello">
              {t('hello')}{' '}
              <b className="openHeader_hello--name">
                {resident?.resident?.firstname} {resident?.resident?.lastname}
              </b>{' '}
              !
            </p>
            <Link className="openHeader_link" to="/my-account">
              <img
                className="openHeader_icon"
                src="/icons/user.svg"
                alt={t('myaccount')}
              />
              <p className="openHeader_text">{t('myaccount')}</p>
            </Link>
            <Link className="openHeader_link" to="/my-residence">
              <img
                className="openHeader_icon"
                src="/icons/residence.svg"
                alt={t('myresidence')}
              />
              <p className="openHeader_text">{t('myresidence')}</p>
            </Link>

            {/* TODO : Link when page will be created */}
            <Link className="openHeader_link" to="/reservations">
              <img
                className="openHeader_icon"
                src="/icons/shopping-bag.svg"
                alt={t('commands')}
              />
              <p className="openHeader_text">{t('commands')}</p>
            </Link>

            <Link className="openHeader_link" to="/faq">
              <img
                className="openHeader_icon"
                src="/icons/help-circle.svg"
                alt={t('questions')}
              />
              <p className="openHeader_text">{t('questions')}</p>
            </Link>

            <Link className="openHeader_link" to="/incident-form">
              <img
                className="openHeader_icon"
                src="/icons/shuffle.svg"
                alt={t('incident')}
              />
              <p className="openHeader_text">{t('incident')}</p>
            </Link>

            <a
              className="openHeader_link"
              href="https://www.vilogia.fr/mentions-legales"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="openHeader_icon"
                src="/icons/shield.svg"
                alt={t('legalMentions')}
              />
              <p className="openHeader_text">{t('legalMentions')}</p>
            </a>

            {window.bcdDeferredPrompt !== undefined && (
              <button
                type="button"
                className="openHeader_link"
                style={{ paddingLeft: 0 }}
                onClick={() => {
                  installPwa()
                }}
              >
                <img
                  className="openHeader_icon"
                  src="/icons/shield.svg"
                  alt={t('legalMentions')}
                />
                <p className="openHeader_text">{t('install')}</p>
              </button>
            )}

            <div className="openHeader_deconection">
              <button
                type="button"
                className="openHeader_deco"
                onClick={() => {
                  localStorage.removeItem('access_token')
                  localStorage.removeItem('refresh_token')
                  history.push('/')
                }}
              >
                <img
                  className="openHeader_power"
                  src="/icons/power.svg"
                  alt={t('altPower')}
                />
                <p className="openHeader_deconect">{t('deconnect')}</p>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            fromServices
              ? 'miniHeader_withoutBoxShadow miniHeader'
              : 'miniHeader'
          }
        >
          <button
            className="miniHeader_titleButton"
            type="button"
            onClick={() => history.push('/home')}
          >
            <img
              className="miniHeader_logo"
              src="/images/logo-v-services-dec22.png"
              alt={t('altLogo')}
            />
          </button>

          <button
            className="miniHeader_burger"
            type="button"
            onClick={() => setIsMenuOpen(true)}
          >
            <img
              className="header_kebab"
              src={
                fromServices
                  ? '/icons/kebabMenuWhite.svg'
                  : '/icons/kebabMenu.svg'
              }
              alt={t('altLogo')}
            />
          </button>
        </div>
      )}
    </div>
  )
}

WidgetHeader.defaultProps = {
  fromServices: undefined,
}

WidgetHeader.propTypes = {
  fromServices: PropTypes.bool,
}
