import PropTypes from 'prop-types'
import './ServiceCard.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import config from '../../config'

export default function ServiceCard({ service, description }) {
  const { t } = useTranslation('home')
  //const history = useHistory()

  const getImage = () => {
    let url = `${config.API_BASE_URL}/services/${service.id}/assets/picto.png`
    url += `?token=${localStorage.getItem('access_token')}`
    return url
  }

  const isInVisible =
    service.residenceServicesByIdService.nodes[0].serviceStatusByIdServiceStatus
      .name === 'INVISIBLE'
  const isUnavailable =
    service.residenceServicesByIdService.nodes[0].serviceStatusByIdServiceStatus
      .name === 'INDISPONIBLE'

  return (
    <div>
      {!isInVisible && !isUnavailable && (
        <Link
          className="serviceCard"
          to={{
            pathname: description.includes('https')
              ? description
              : `/services/${service?.residenceServicesByIdService?.nodes[0]?.id}`,
          }}
          target={description.includes('https') && '_blank'}
        >
          <img
            className="serviceCard_icon"
            src={getImage()}
            alt={t('altIcon')}
          />
          <p className="serviceCard_label">{service.label}</p>
        </Link>
      )}
      {!isInVisible && isUnavailable && (
        <span className="serviceCard_disabled">
          <img
            className="serviceCard_icon_disabled"
            src={getImage()}
            alt={t('altIcon')}
          />
          <p className="serviceCard_label">{service.label}</p>
        </span>
      )}
    </div>
  )
}
ServiceCard.propTypes = {
  service: PropTypes.objectOf(PropTypes.any),
  description: PropTypes.string,
}
