import { gql } from '@apollo/client'

const getIssueTypes = gql`
  query getIssueTypes {
    issueTypes {
      nodes {
        label
        id
      }
    }
  }
`

const createIncident = gql`
  mutation createIssue(
    $description: String!
    $idIssueType: UUID!
    $idResident: UUID!
  ) {
    createIssue(
      input: {
        issue: {
          idResident: $idResident
          idIssueType: $idIssueType
          description: $description
        }
      }
    ) {
      clientMutationId
    }
  }
`

export default {
  getIssueTypes,
  createIncident,
}
