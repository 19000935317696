/* eslint-disable no-undef */
const BASE_URL = {
  dev: 'https://logifim-dev.bettercalldave.io/ws',
  uat: 'https://logifim-uat.bettercalldave.io/ws',
  prod: 'https://app.v-services.org/ws',
}

const GRAPHQL_URLS = {
  dev: 'https://logifim-dev.bettercalldave.io/graphql',
  uat: 'https://logifim-uat.bettercalldave.io/graphql',
  prod: 'https://app.v-services.org/graphql',
}

const STRIPE_PUBLISH_KEYS = {
  dev: 'pk_test_51Kw0DeC2KblHgHAr7Exw3dX7CqZGKDjEJAVMj02bfhDz4pNGojpmGdGLFDHv9a0w0YI1QPiC3ZG0CEjQetmUlVWa00hFZjmjXD',
  uat: 'pk_test_51LhBseAFSGHizEvu5j3LvgJE1tv1b3jRQEWAzSs51WlJyqYjiCjNjfIYsPs8502I7XCU3RawbTJSg9ONJyqvJPmF00IYZmyPz8',
  prod: 'pk_live_51LhBseAFSGHizEvuHyAUE8h8I7VfTkXdUaDGGUgbRRYHCNI6NhNIku7UplZPlNhk3yaIbAGVGcL2VWpzKubTmojl00x1Mol1N6',
}

const {
  REACT_APP_INFRA = 'dev',
  REACT_APP_PREFIX = '',
  NODE_ENV = 'dev',
} = process.env
const API_BASE_URL = BASE_URL[REACT_APP_INFRA]
const GRAPHQL_URL = GRAPHQL_URLS[REACT_APP_INFRA]
const STRIPE_PUBLISH_KEY = STRIPE_PUBLISH_KEYS[REACT_APP_INFRA]

export default {
  API_BASE_URL,
  REACT_APP_PREFIX,
  REACT_APP_INFRA,
  GRAPHQL_URL,
  NODE_ENV,
  authorizedRole: ['logifim_resident', 'logifim_external'],
  STRIPE_PUBLISH_KEY,
}
