import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'

export const isUserTokenExpired = (token) => {
  if (token) {
    const { exp } = jwtDecode(token)
    return !exp || dayjs().isAfter(dayjs(exp * 1000))
  }
  return true
}

export const getUserId = (token) => {
  if (token) {
    const { id } = jwtDecode(token)
    return id
  } else {
    return false
  }
}

export const isAuthorized = (role, requiredRole) => {
  if (requiredRole.indexOf(role) > -1) {
    return true
  }
  return false
}

export const getUserRole = (token) => {
  if (token) {
    const { role } = jwtDecode(token)
    return role
  } else {
    return false
  }
}
