import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import UIButton from '../../components/_UI/UIButton/UIButton'
import './Landing.scss'

const Landing = () => {
  const { t } = useTranslation('landing')
  const history = useHistory()

  return (
    <div className="landing">
      <div className="circleBackground-landing">
        <div className="circleBackground-landing_red" />
        <div className="circleBackground-landing_green" />
        <div className="circleBackground-landing_transparent" />
      </div>
      <div className="landingInformations">
        <img
          className="logoLogifirm"
          src="/images/logo-v-services-dec22.png"
          alt={t('altLogo')}
        />
        <p className="landingInformations_portal">{t('ourServices')}</p>
      </div>
      <div className="landingInformations_buttons">
        <UIButton
          label={t('haveAccount')}
          size="full"
          backgroundColor="bgGreen"
          onClick={() => history.push('/login')}
        />
      </div>
    </div>
  )
}

export default Landing
