import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Incident as IncidentGql } from '../../../gql'
import { getUserId } from '../../../lib/helpers'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import UIButton from '../../_UI/UIButton/UIButton'
import UISelect from '../../_UI/UISelect/UISelect'
import UITextArea from '../../_UI/UITextArea/UITextArea'
import './WidgetFormIncident.scss'

export default function WidgetFormIncident({
  placeholder,
  dsc,
  validationMessage,
}) {
  const { t } = useTranslation('incidentForm')
  const access_token = localStorage.getItem('access_token')
  const idUser = getUserId(access_token)
  const [count, setCount] = useState(0)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [motif, setMotif] = useState('')
  const [send, setSend] = useState(false)
  const [optionIncident, setOptionIncident] = useState()

  const countCharacter = (event) => {
    setCount(event.target.value.length)
    setMessage(event.target.value)
    if (event.target.value.length === 1500) {
      setError(true)
    }
  }

  const { data: resident } = useQuery(IncidentGql.getIssueTypes, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setOptionIncident(response?.issueTypes?.nodes)
      setMotif(response?.issueTypes?.nodes[0]?.id)
    },
    onError: (err) => {
      const request = IncidentGql.getIssueTypes
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: resident?.resident?.idResidence,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [createIncident] = useMutation(IncidentGql.createIncident, {
    variables: {
      description: message,
      idIssueType: motif,
      idResident: idUser,
    },
    onCompleted: () => {
      setSend(true)
    },
    onError: (err) => {
      const event = {
        request: IncidentGql.createIncident?.definitions[0]?.name?.value,
        variables: {
          description: message,
          idIssueType: motif,
          idResident: idUser,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const sendIncident = () => {
    createIncident()
  }

  return (
    <div className="widgetFormIncident">
      {send && (
        <p className="widgetFormIncident_validate">{validationMessage}</p>
      )}
      <div style={{ visibility: send ? 'hidden' : 'visible' }}>
        {optionIncident && (
          <UISelect
            size="full"
            options={optionIncident}
            onChange={(e) => setMotif(e.target.value)}
            value={motif}
          />
        )}

        <UITextArea
          placeholder={placeholder}
          onChange={(event) => {
            countCharacter(event)
          }}
          value={count}
        />
        <p className="widgetFormIncident_count">
          {`${t('limit')} 
          ${count} ${t(t('1500'))}`}
        </p>
        {error && <p>{t('error')}</p>}
        <p className="widgetFormIncident_dsc">{dsc}</p>
        <UIButton
          label={t('btnLabel')}
          size="full"
          backgroundColor="bgGreen"
          color="white"
          onClick={sendIncident}
        />
      </div>
    </div>
  )
}

WidgetFormIncident.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  dsc: PropTypes.string.isRequired,
  validationMessage: PropTypes.string.isRequired,
}
