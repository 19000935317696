import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './MyAccountForm.scss'

import UIInput from '../_UI/UIInput/UIInput'
import UIButton from '../_UI/UIButton/UIButton'
import { getUserRole } from '../../lib/helpers'

export default function MyAccountForm({
  residentNumber,
  lastnameValue,
  lastnameOnChange,
  firstnameValue,
  firstnameOnChange,
  emailValue,
  emailOnChange,
  phoneNumberValue,
  phoneNumberOnChange,
  birthdayValue,
  birthdayOnChange,
  isFormModified,
  register,
  loading,
  error,
  submitOk,
}) {
  const { t } = useTranslation('myAccount')
  const access_token = localStorage.getItem('access_token')
  const infoUser = getUserRole(access_token)

  return (
    <div className="personalInformations">
      <p className="personalInformations_title">{t('personalInformations')}</p>
      {residentNumber && (
        <div className="personalInformations_input">
          <p>
            <span>{`${t('residentNumber')} : `}</span>
            {residentNumber}
          </p>
        </div>
      )}
      <div className="personalInformations_input">
        <UIInput
          kind="secondary"
          size="full"
          value={lastnameValue}
          onChange={lastnameOnChange}
          placeholder={t('placeHolderLastname')}
        />
      </div>

      <div className="personalInformations_input">
        <UIInput
          kind="secondary"
          size="full"
          value={firstnameValue}
          onChange={firstnameOnChange}
          placeholder={t('placeHolderFirstname')}
        />
      </div>
      <div className="personalInformations_input">
        <UIInput
          kind="secondary"
          size="full"
          type="email"
          value={emailValue}
          onChange={emailOnChange}
          placeholder={t('placeHolderEmail')}
        />
      </div>

      <div className="personalInformations_input">
        <UIInput
          kind="secondary"
          size="full"
          type="tel"
          value={phoneNumberValue}
          onChange={phoneNumberOnChange}
          placeholder={t('placeHolderPhoneNumber')}
          iconPath="icons/phone.svg"
        />
      </div>

      {infoUser === 'logifim_resident' && (
        <div className="personalInformations_input">
          <UIInput
            type="date"
            kind="secondary"
            size="full"
            value={birthdayValue}
            onChange={birthdayOnChange}
            placeholder={t('placeHolderBirthday')}
            iconPath="icons/calendar.svg"
          />
        </div>
      )}
      <div className="submit_button">
        <UIButton
          label={t('register')}
          onClick={register}
          size="full"
          backgroundColor={isFormModified ? 'bgTransparent' : 'bgGreen'}
          color={isFormModified ? 'gray' : 'white'}
          disabled={isFormModified || loading}
          style={{ margin: 0 }}
        />
        {error && <p className="personalInformations_error">{t('error')}</p>}
        {submitOk && (
          <p className="personalInformations_submitOk">{t('submitOk')}</p>
        )}
      </div>
    </div>
  )
}
MyAccountForm.propTypes = {
  residentNumber: PropTypes.string,
  lastnameValue: PropTypes.string.isRequired,
  lastnameOnChange: PropTypes.func.isRequired,
  firstnameValue: PropTypes.string.isRequired,
  firstnameOnChange: PropTypes.func.isRequired,
  emailValue: PropTypes.string.isRequired,
  emailOnChange: PropTypes.func.isRequired,
  phoneNumberValue: PropTypes.string.isRequired,
  phoneNumberOnChange: PropTypes.func.isRequired,
  birthdayValue: PropTypes.string.isRequired,
  birthdayOnChange: PropTypes.func.isRequired,
  isFormModified: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitOk: PropTypes.bool.isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
}

MyAccountForm.defaultProps = {
  residentNumber: null,
}
