import React from 'react'
import { useTranslation } from 'react-i18next'
import WidgetHeader from '../../components/_Widget/WidgetHeader/WidgetHeader'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import UIHeaderForm from '../../components/_UI/UIHeaderForm/UIHeaderForm'
import './IncidentForm.scss'
import WidgetFormIncident from '../../components/_Widget/WidgetFormIncident/WidgetFormIncident'

const IncidentForm = () => {
  const { t } = useTranslation('incidentForm')

  const optionIncident = [
    { name: t('select.default') },
    { name: t('select.accommodation') },
    { name: t('select.residence') },
    { name: t('select.service') },
    { name: t('select.other') },
  ]

  return (
    <div className="incidentForm">
      <WidgetHeader />
      <UIBackButton onClick={() => window.history.back()} />
      <UIHeaderForm title={t('title')} subTitle={t('subTitle')} />
      <WidgetFormIncident
        options={optionIncident}
        placeholder={t('placeholder')}
        dsc={t('dsc')}
        validationMessage={t('send')}
      />
    </div>
  )
}

export default IncidentForm
