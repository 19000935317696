import { gql } from '@apollo/client'

const getServiceData = gql`
  query getServiceData($id: UUID!, $startDatetime: Datetime!) {
    residenceService(id: $id) {
      isForResident
      availabilityMode
      isForExternal
      externalPrice
      prepareDelay
      residentPrice
      bookingLength
      bookingLimit
      isCommentedBooking
      bookingType
      endDatetime
      startDatetime
      catalogByIdExternalCatalogue {
        catalogArticlesByIdCatalog {
          nodes {
            articleByIdArticle {
              label
              id
              status
              unitPricesByIdArticle {
                nodes {
                  value
                  currencyByIdCurrency {
                    symbol
                  }
                }
              }
            }
          }
        }
      }
      catalogByIdResidentCatalogue {
        catalogArticlesByIdCatalog {
          nodes {
            articleByIdArticle {
              label
              id
              status
              idVatRate
              unitPricesByIdArticle {
                nodes {
                  value
                  currencyByIdCurrency {
                    symbol
                  }
                }
              }
              vatRateByIdVatRate {
                rate
              }
            }
          }
        }
      }
      serviceByIdService {
        id
        description
        name
        url
        label
        ref
        externalByIdServiceProvider {
          id
        }
      }
      residenceServiceAvailabilityRangesByIdResidenceService(
        filter: { endDatetime: { greaterThan: $startDatetime } }
      ) {
        nodes {
          id
          endDatetime
          duration
          repeatParameterValue
          repeatValue
          serviceRepeatParameterByIdRepeatParameter {
            id
            name
          }
          serviceRepeatUnitByIdRepeatUnit {
            id
            name
          }
          startDatetime
        }
      }
    }
  }
`

const sendReservationService = gql`
  mutation sendReservationService(
    $idResidenceService: UUID!
    $startDatetime: String
    $endDatetime: String
    $idResident: UUID
    $idExternal: UUID
  ) {
    createResidenceServiceBooking(
      input: {
        residenceServiceBooking: {
          idResidenceService: $idResidenceService
          startDatetime: $startDatetime
          endDatetime: $endDatetime
          status: NEW
          idResident: $idResident
          idExternal: $idExternal
        }
      }
    )
  }
`

const createResidenceServiceBooking = gql`
  mutation createResidenceServiceBooking(
    $comments: String
    $endDatetime: Datetime!
    $idResidenceService: UUID!
    $startDatetime: Datetime!
    $status: BookingStatus = NEW
    $idExternal: UUID
    $idResident: UUID
  ) {
    createResidenceServiceBooking(
      input: {
        residenceServiceBooking: {
          idResidenceService: $idResidenceService
          startDatetime: $startDatetime
          endDatetime: $endDatetime
          status: $status
          comments: $comments
          idResident: $idResident
          idExternal: $idExternal
        }
      }
    ) {
      clientMutationId
      residenceServiceBooking {
        id
      }
    }
  }
`

const createOrder = gql`
  mutation createOrder($idUser: UUID!, $idService: UUID!) {
    createOrder(input: { order: { idUser: $idUser, idService: $idService } }) {
      clientMutationId
      order {
        id
      }
    }
  }
`

const createOrderArticle = gql`
  mutation createOrderArticle(
    $idOrder: UUID!
    $idArticle: UUID!
    $quantity: Int
  ) {
    createOrderArticle(
      input: {
        orderArticle: {
          idOrder: $idOrder
          idArticle: $idArticle
          quantity: $quantity
        }
      }
    ) {
      clientMutationId
    }
  }
`

const fetchBookingsByResident = gql`
  query MyQuery($id: UUID!) {
    residenceServiceBookings(
      filter: { status: { notEqualTo: REFUSED } }
      condition: { idResident: $id }
    ) {
      nodes {
        totalAmount
        comments
        id
        residenceServiceByIdResidenceService {
          id
          residentPrice
          serviceByIdService {
            name
          }
        }
        startDatetime
        endDatetime
        createdAt
        status
        orderArticlesByIdOrder {
          nodes {
            id
            quantity
            articleByIdArticle {
              label
            }
          }
        }
        paymentsByIdOrder {
          nodes {
            id
          }
        }
      }
    }
  }
`

const cancelResidenceServiceBooking = gql`
  mutation cancelBooking($id: UUID!) {
    updateResidenceServiceBooking(
      input: { patch: { status: ABONDONNED }, id: $id }
    ) {
      residenceServiceBooking {
        id
        status
      }
    }
  }
`

export default {
  getServiceData,
  sendReservationService,
  createResidenceServiceBooking,
  fetchBookingsByResident,
  cancelResidenceServiceBooking,
  createOrder,
  createOrderArticle,
}
