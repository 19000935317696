import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './Notifications.scss'
import { DateTime } from 'luxon'

export default function Notifications({
  notification,
  display,
  opened,
  image,
}) {
  const { t } = useTranslation('myAccount')

  return (
    <>
      <div className="notification">
        <button
          type="button"
          className="notification_text"
          onClick={() => opened()}
        >
          <div className="notification_desc">
            <p className="notification_hour">
              {DateTime.fromISO(notification.createdAt)
                .setLocale('fr')
                .toFormat('dd LLL yyyy')}
            </p>
            <p className="notification_title">{notification.title}</p>
          </div>
          <p className="notification_infocontainer">
            <img
              className="notification_info"
              src="icons/info.svg"
              alt={t('altInfo')}
            />
          </p>
        </button>

        <button
          type="button"
          className="notification_close"
          onClick={() => display(notification.id)}
        >
          <div className="close">
            <img
              className="close_img"
              src="icons/close.svg"
              alt={t('altClose')}
            />
          </div>
        </button>
      </div>
      {notification.opened && (
        <div className="notificationOpen">
          {image && (
            <img
              src={image}
              className="notificationsImage"
              alt={t('alPublication')}
            />
          )}
          <p className="notificationOpen_text">{notification.body}</p>
        </div>
      )}
    </>
  )
}

Notifications.propTypes = {
  notification: PropTypes.object.isRequired,
  display: PropTypes.func.isRequired,
  opened: PropTypes.func.isRequired,
  image: PropTypes.any,
}
