/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import api from '../../api'
import UIBackButton from '../../components/_UI/UIBackButton/UIBackButton'
import UIButton from '../../components/_UI/UIButton/UIButton'
import UIInput from '../../components/_UI/UIInput/UIInput'
import './ForgotPassword.scss'

const ForgotPassword = () => {
  const { t } = useTranslation('forgotPassword')
  const history = useHistory()
  const location = useLocation()

  const emailRegEx = /^[a-z.]+@[a-z]+\.[a-z.]{2,}/i

  const [email, setEmail] = useState(
    emailRegEx.test(location?.state?.login) ? location?.state?.login : ''
  )
  // const [securityQuestion, setSecurityQuestion] = useState('')
  const [isContinue, setIsContinue] = useState(false)

  const [disableButton, setDisableButton] = useState(false)

  const [sendStatus, setSendStatus] = useState('default')

  const sendLoginForReset = async () => {
    if (email) {
      if (emailRegEx.test(email)) {
        setDisableButton(true)
        try {
          await api
            .sendEmailForgotPassword(email.toLowerCase())
            .then(() => setSendStatus('valid'))
        } catch (e) {
          setSendStatus('error')
          console.log(e)
        }
      } else {
        setSendStatus('error')
      }
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendLoginForReset()
    }
  }

  return (
    <div className="forgotPassword">
      <div className="forgotPassword_circleBackground">
        <div className="forgotPassword_circleBackground--red" />
        <div className="forgotPassword_circleBackground--green" />
        <div className="forgotPasswordContainer">
          {isContinue && (
            <div className="forgotPasswordContainer_back">
              <UIBackButton
                onClick={() => {
                  setIsContinue(false)
                  setSendStatus('default')
                }}
              />
            </div>
          )}
          <img
            className="logoLogifirm-login"
            src="/images/logo-v-services-dec22.png"
            alt={t('altLogo')}
          />
        </div>
        <div className="circleBackground_transparent" />
      </div>

      <div className="secondContainerForgot">
        <div className="resetEmail">
          <p className="resetEmail_text">{t('resetEmail')}</p>
          <div className="resetEmail_input">
            <UIInput
              type="email"
              label={t('inputEmail')}
              placeholder={t('placeholderEmail')}
              value={email}
              onChange={setEmail}
              status={sendStatus}
              enterkeyhint="send"
              onKeyPress={(event) => handleKeyPress(event)}
              errorLabel={t('errorEmail')}
              validIcon=""
              style={{ width: 'calc(100% - 17px)' }}
            />
          </div>
          <UIButton
            label={t('resetPassword')}
            kind="primary"
            backgroundColor="bgRed"
            size="full"
            disabled={disableButton}
            onClick={sendLoginForReset}
          />
          <UIButton
            label={t('back')}
            kind="secondary"
            color="black"
            backgroundColor="bgTransparent"
            size="full"
            onClick={() => history.push('/login')}
          />
          {disableButton && <p>{t('validEmail')}</p>}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
