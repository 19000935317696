import { gql } from 'apollo-boost'

const getFaqs = gql`
  query getFaq {
    faqs(orderBy: POS_ASC) {
      nodes {
        id
        question
        answer
        pos
      }
    }
  }
`

export default { getFaqs }
